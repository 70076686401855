import {
  arrayUnion,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  writeBatch,
  query,
  where,
  onSnapshot,
  Timestamp,
  deleteDoc,
} from "firebase/firestore"
import db from "../firebaseConfig"
import { getAuth } from "firebase/auth"
import { functions } from "@/firebaseConfig"
import { httpsCallable } from "firebase/functions"
import { isDateRangeCovered } from "./helpers.js"
import moment from "moment"

const fbCollectionsInfo = {
  // General
  Channels: null,
  Coupons: null,
  Menus: "restaurantId",
  MenuItems: "restaurantId",
  MenuItemCategories: null,
  ModifierLists: "restaurantId",
  ModifierItems: null,
  Orders: null,
  LoyaltyRewards: null,
  PromoImages: null,
  Refunds: null,
  Restaurants: "id",
  Settings: null,
  Taxes: null,
  ThirdPartyTypes: null,
  Users: null,
  Devices: null,
  // Inventory
  Counts: null,
  Items: null,
  InventoryItemsLog: null,
  OrderGuides: null,
  PurchaseOrders: null,
  Receiving: null,
  Storages: null,
  SupplierItems: "restaurantId",
  Units: null,
  Writeoffs: null,
}

const organizations = collection(db, "Organizations-v1")

export async function fetchFirebaseRefs({ commit }) {
  const currentUser = getAuth().currentUser
  if (!currentUser) return
  const uid = currentUser.uid
  const userEmail = currentUser.email
  const querySnapshot = await getDocs(query(organizations))
  try {
    const fbCollections = {}

    const userOrg = querySnapshot.docs.find(doc => {
      const thisOrg = doc.data()
      return thisOrg.author === userEmail || thisOrg.access?.[uid] !== undefined
    })

    if (userOrg) {
      const userOrgData = userOrg.data()
      fbCollections.organization = userOrgData
      fbCollections.organization.id = userOrg.id
      if (userOrgData.access && userOrgData.access[uid] !== undefined) {
        fbCollections.access = userOrgData.access[uid]
        fbCollections.access.uid = currentUser.uid
      }
    } else {
      console.error("No organization found for user", userEmail)
      return
    }

    const fbGetters = { ...fbCollections }
    for (const [collectionName, restaurantId] of Object.entries(fbCollectionsInfo)) {
      let name = collectionName.toLowerCase() + "Ref"
      const thisCollection = collection(
        db,
        "Organizations-v1",
        fbCollections.organization.id,
        collectionName
      )
      fbCollections[name] = thisCollection

      if (
        fbCollections.access !== undefined &&
        fbCollections.access.restaurantIds !== undefined &&
        fbCollections.access.restaurantIds.length > 0 &&
        restaurantId
      ) {
        fbGetters[name] = query(
          thisCollection,
          where(restaurantId, "in", fbCollections.access.restaurantIds)
        )
      } else {
        fbGetters[name] = thisCollection
      }
    }
    commit("setFirebaseRefs", fbCollections)
    commit("setFirebaseGetters", fbGetters)

    if (fbCollections.access !== undefined) {
      commit("setCurrentUserRole", fbCollections.access.role)
      commit("setCurrentUserAccess", fbCollections.access)
    } else {
      commit("setCurrentUserRole", "Admin")
    }
  } catch (e) {
    console.error("error", e)
  }
}

export async function fetchAllData({ dispatch, commit }) {
  await dispatch("fetchFirebaseRefs")
  await Promise.all([
    dispatch("fetchOrgDoc"),
    dispatch("fetchSettings"),
    dispatch("fetchRestaurants"),
  ]).then(() => {
    commit("setLoadProgress", 0.3)
  })
  await Promise.all([
    dispatch("fetchOnboardings"),
    dispatch("fetchOrders"),
    dispatch("fetchChannels"),
    dispatch("updateRestaurantSyncStatus"),
    dispatch("fetchCustomers"),
  ]).then(() => {
    commit("setLoadProgress", 0.6)
  })
  return Promise.all([
    dispatch("fetchUsers"),
    dispatch("fetchCuisines"),
    dispatch("fetchThirdPartyTypes"),
    dispatch("fetchTaxes"),
    dispatch("fetchCoupons"),
    dispatch("fetchModifierLists"),
    dispatch("fetchModifierItems"),
    dispatch("fetchMenuItemCategories"),
  ]).then(() => {
    commit("setLoadProgress", 1)
  })
}

export async function fetchAllSquareRestaurantData({ dispatch }) {
  await dispatch("fetchRestaurants")
  await Promise.all([
    dispatch("fetchChannels"),
    dispatch("fetchMenus"),
    dispatch("fetchMenuItems"),
    dispatch("fetchModifierLists"),
    dispatch("fetchModifierItems"),
    dispatch("fetchTaxes"),
  ])
}

export async function fetchInventoryData({ dispatch, commit, state }) {
  if (state.inventoryFetched) return
  commit("setLoadProgress", 0)
  await Promise.all([
    dispatch("fetchMenus"),
    dispatch("fetchMenuItems"),
    dispatch("fetchInventoryItems"),
    dispatch("fetchSupplierItems"),
    dispatch("fetchStorages"),
    dispatch("fetchUnits"),
    dispatch("fetchOrderGuides"),
  ]).then(() => {
    commit("setLoadProgress", 0.5)
  })
  await Promise.all([
    dispatch("fetchCounts"),
    dispatch("fetchPurchaseOrders"),
    dispatch("fetchOrderGuides"),
    dispatch("fetchWriteoffs"),
  ]).then(() => {
    commit("setLoadProgress", 1)
    commit("setInventoryFetched", true)
  })
}

/**
 * Fetches onboardings for the current user
 * If the user is an admin, it fetches the organization onboardings (there's just 1 onboarding in this case)
 * If the user is a manager, it fetches the onboardings for EACH restaurant (there's 1 onboarding per restaurant)
 */
export async function fetchOnboardings({ commit, dispatch, state }) {
  // state.currentUserRole === "Admin" && !isNextGenUser
  // if (state.currentUserRole !== "Admin" && !state.firebaseGetters.access.restaurantIds.length) {
  //   commit("setRestaurants", [])
  // }

  const onboardings = []

  if (state.settings.singleBrandEnabled) {
    const onboarding = state.settings.onboarding
    if (onboarding) {
      onboardings.push(onboarding)
    } else {
      const newOnboarding = {
        steps: {
          settings: {
            name: "settings",
            title: "General Information",
            description:
              "Let’s start by entering the general information about " +
              (state.settings.organizationName ?? "your account") +
              ".",
            completed: false,
            timestamp: null,
            index: 0,
          },
          restaurants: {
            name: "restaurants",
            title: "Restaurants",
            description:
              "Add restaurants and invite them to complete their details.",
            completed: false,
            timestamp: null,
            index: 1,
          },
          payments: {
            name: "payments",
            title: "Payments",
            description:
              "Set up your payment provider to ensure you and your restaurants can get paid.",
            completed: false,
            timestamp: null,
            index: 2,
          },
          branding: {
            name: "branding",
            title: "Branding",
            description:
              "Set up your app logo and colors to create a look that represents your brand.",
            completed: false,
            timestamp: null,
            index: 3,
          },
        },
      }
      onboardings.push(newOnboarding)
      dispatch("updateOnboardings", {
        onboarding: newOnboarding,
        type: "organization",
      })
    }
  } else if (state.currentUserRole === "Admin") {
    const onboarding = state.settings.onboarding
    if (onboarding) {
      // when does this happen??? when we return to the onboarding
      onboardings.push(onboarding)
    } else {
      const newOnboarding = {
        steps: {
          settings: {
            name: "settings",
            title: "General Information",
            description:
              "Let’s start by entering the general information about " +
              (state.settings.organizationName ?? "your account") +
              ".",
            completed: false,
            timestamp: null,
            index: 0,
          },
          branding: {
            name: "branding",
            title: "Branding",
            description:
              "Set up your app logo and colors to create a look that represents your brand.",
            completed: false,
            timestamp: null,
            index: 1,
          },
          payments: {
            name: "payments",
            title: "Payments",
            description:
              "Set up your payment provider to ensure you and your restaurants can get paid.",
            completed: false,
            timestamp: null,
            index: 2,
          },
          charges: {
            name: "charges",
            title: "Payment Settings",
            description:
              "Add automatic commission and set your tipping preferences.",
            substep: true,
            completed: false,
            timestamp: null,
            index: 3,
          },
          restaurants: {
            name: "restaurants",
            title: "Restaurants",
            description:
              "Add restaurants and invite them to complete their details.",
            completed: false,
            timestamp: null,
            index: 4,
          },
        },
      }
      onboardings.push(newOnboarding)
      dispatch("updateOnboardings", {
        onboarding: newOnboarding,
        type: "organization",
      })
    }
  } else if (state.currentUserRole === "Manager") {
    state.restaurants.forEach(restaurant => {
      if (restaurant.onboarding) {
        onboardings.push({
          ...restaurant.onboarding,
          restaurantId: restaurant.id,
        })
      } else {
        const newOnboarding = {
          steps: {
            restaurants: {
              name: "restaurants",
              title: "Restaurant Information",
              description:
                "Get started by filling out the basic details of your restaurant!",
              completed: false,
              timestamp: null,
              index: 0,
            },
            branding: {
              name: "branding",
              title: "Branding",
              description:
                "Add your logo and hero image to create a consistent look for your restaurant.",
              completed: false,
              timestamp: null,
              index: 1,
            },
            payments: {
              name: "payments",
              title: "Payment",
              description:
                "Complete your payment details to ensure you can receive your funds.",
              completed: false,
              timestamp: null,
              index: 2,
            },
          },
          restaurantId: restaurant.id,
        }
        dispatch("updateOnboardings", {
          onboarding: newOnboarding,
          type: "restaurants",
          id: restaurant.id,
        })
        onboardings.push(newOnboarding)
      }
    })
  }

  commit("setOnboardings", onboardings)

  const firstIncompleteCurrentOnboardingIndex = onboardings.findIndex(
    o => !Object.values(o.steps).every(s => s.completed)
  )

  if (firstIncompleteCurrentOnboardingIndex !== -1) {
    commit("setCurrentOnboardingIndex", firstIncompleteCurrentOnboardingIndex)
  }

  commit("setLoadText", "Loaded onboarding...")
}

export async function updateCurrentOnboardingStep(
  { commit, dispatch, state },
  payload
) {
  const newOnboarding = {
    ...state.onboardings[state.currentOnboardingIndex],
    steps: {
      ...state.onboardings[state.currentOnboardingIndex].steps,
      [payload.step]: {
        ...state.onboardings[state.currentOnboardingIndex].steps[payload.step],
        completed: payload.completed,
        timestamp: Timestamp.fromDate(new Date()),
      },
    },
  }

  commit("setCurrentOnboarding", newOnboarding)

  dispatch("updateOnboardings", {
    onboarding: newOnboarding,
    type: payload.type,
    id: payload.id,
  })
}

/**
 * Updates the onboarding for the current user
 * If the user is an admin, it updates the organization onboarding
 * If the user is a manager, it updates the onboarding for the restaurant
 */
export async function updateOnboardings({ state }, payload) {
  const payloadDoc =
    payload.type === "organization"
      ? doc(state.firebaseGetters.settingsRef, "Organization")
      : doc(state.firebaseRefs.restaurantsRef, payload.id)
  await setDoc(payloadDoc, { onboarding: payload.onboarding }, { merge: true })
}

export async function updateRestaurantSyncStatus({ commit, state }) {
  if (state.restaurants?.length && state.channels?.length) {
    const restaurantSyncStatus = {}
    state.restaurants.forEach(restaurant => {
      restaurantSyncStatus[restaurant.id] = false
      try {
        const channel = state.channels.find(
          channel =>
            channel.restaurantData &&
            channel.restaurantData[0].restaurantId == restaurant.id
        )
        if (channel != undefined && "accessToken" in channel) {
          restaurantSyncStatus[restaurant.id] = true
        }
      } catch (e) {
        console.error("error", e)
      }
    })
    commit("setRestaurantSyncStatus", restaurantSyncStatus)
  }
}

export async function fetchCuisines({ commit }) {
  const cuisinesCollection = collection(db, "Cuisines")
  const cuisinesSnapshot = await getDocs(cuisinesCollection)
  if (!cuisinesSnapshot || cuisinesSnapshot.empty) return
  const cuisines = []
  cuisinesSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      cuisines.push(doc.data())
    }
  })
  commit("setCuisines", cuisines)
  commit("setLoadText", "Loaded cuisines...")
}

export async function fetchMenus({ commit, state }) {
  var menus = []
  var menusSnapshot = await getDocs(state.firebaseGetters.menusRef)
  menusSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      menus.push(doc.data())
    }
  })
  commit("setMenus", menus)
  commit("setLoadText", "Loaded menus...")
}

export async function updateMenus({ dispatch, state }, payload) {
  var menuID
  if (payload.id) {
    menuID = payload.id
  } else {
    menuID = doc(state.firebaseRefs.menusRef).id
    payload.id = menuID
  }
  await setDoc(doc(state.firebaseRefs.menusRef, menuID), payload, {
    merge: true,
  }).then(dispatch("fetchMenus"))
}

export async function deleteMenu({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.menusRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchMenus"))
}

export async function fetchMenuItems({ commit, state }) {
  var menuItems = []
  var menuItemsSnapshot = await getDocs(state.firebaseGetters.menuitemsRef)
  menuItemsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      menuItems.push(doc.data())
    }
  })
  commit("setMenuItems", menuItems)
  commit("setLoadText", "Loaded menu items...")
}

export async function deleteMenuItem({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.menuitemsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchMenuItems"))
}

export async function fetchRestaurants({ commit, dispatch, state }) {
  var restaurants = []
  var restaurantsSnapshot = await getDocs(state.firebaseGetters.restaurantsRef)
  restaurantsSnapshot.forEach(doc => {
    if (doc.data() && (state.showDeleted || !doc.data().isDeleted)) {
      restaurants.push(doc.data())
    }
  })
  //check if snooze time elapsed
  for (let i = 0; i < restaurants.length; i++) {
    if (
      restaurants[i].isSnoozed &&
      restaurants[i].snoozeUntil &&
      restaurants[i].snoozeUntil > 0 &&
      restaurants[i].snoozeUntil <= Date.now()
    ) {
      restaurants[i].isSnoozed = false
      restaurants[i].snoozeUntil = null
      dispatch("updateRestaurantStatus", restaurants[i])
    }
  }
  commit("setRestaurants", restaurants)
  commit("setLoadText", "Loaded restaurants...")
}

export async function fetchMenuItemCategories({ commit, state }) {
  var menuItemCategories = []
  var snapshot = await getDocs(state.firebaseGetters.menuitemcategoriesRef)
  snapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      menuItemCategories.push(doc.data())
    }
  })
  menuItemCategories = menuItemCategories.sort((a, b) => {
    return a.ordinal > b.ordinal ? 1 : -1
  })
  commit("setMenuItemCategories", menuItemCategories)
  commit("setLoadText", "Loaded menu item categories...")
}

export async function updateMenuItemCategories({ dispatch, state }, payload) {
  var menuItemCategoryID
  if (payload.id) {
    menuItemCategoryID = payload.id
  } else {
    menuItemCategoryID = doc(state.firebaseRefs.menuitemcategoriesRef).id
    payload.id = menuItemCategoryID
  }
  await setDoc(
    doc(state.firebaseRefs.menuitemcategoriesRef, menuItemCategoryID),
    payload
  ).then(dispatch("fetchMenuItemCategories"))
}

export async function deleteMenuItemCategory({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.menuitemcategoriesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchMenuItemCategories"))
}

export async function fetchLoyaltyRewards({ commit, state }) {
  const loyaltyRewards = []
  const loyaltyRewardsSnapshot = await getDocs(
    state.firebaseGetters.loyaltyrewardsRef
  )
  if (!loyaltyRewardsSnapshot) return
  loyaltyRewardsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      loyaltyRewards.push(doc.data())
    }
  })
  commit("setLoyaltyRewards", loyaltyRewards)
}

export async function updateLoyaltyReward({ dispatch, state }, payload) {
  if (!payload.id) {
    payload.id = doc(state.firebaseRefs.loyaltyrewardsRef).id
  }

  await setDoc(doc(state.firebaseRefs.loyaltyrewardsRef, payload.id), payload)
  dispatch("fetchLoyaltyRewards")
}

export async function deleteLoyaltyReward({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.loyaltyrewardsRef, payload.id), {
    isDeleted: true,
  })
  dispatch("fetchLoyaltyRewards")
}

export async function toggleLoyaltyRewardStatus({ state }, payload) {
  await updateDoc(doc(state.firebaseRefs.loyaltyrewardsRef, payload.id), {
    isEnabled: payload.isEnabled,
  })
}

export async function updateLoyaltyOptions({ dispatch, state }, payload) {
  const publicDocRef = doc(state.firebaseRefs.settingsRef, "Public")
  await setDoc(publicDocRef, payload, { merge: true })

  dispatch("fetchSettings")
}

export async function fetchPromoImages({ commit, state }) {
  var promoImages = []
  var promoImagesSnapshot = await getDocs(state.firebaseGetters.promoimagesRef)
  if (!promoImagesSnapshot) return
  promoImagesSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      promoImages.push(doc.data())
    }
  })
  promoImages = promoImages.sort((a, b) => {
    return a.startSec > b.startSec ? 1 : -1
  })
  commit("setPromoImages", promoImages)
}

export async function updatePromoImages({ commit, state }, payload) {
  var promoImageID
  if (payload.id) {
    promoImageID = payload.id
  } else {
    promoImageID = doc(state.firebaseRefs.promoimagesRef).id
    payload.id = promoImageID
  }
  await setDoc(doc(state.firebaseRefs.promoimagesRef, payload.id), payload).then(
    commit("addPromoImage", payload)
  )
}

export async function fetchSupplierItems({ commit, state }) {
  var supplierItems = []
  const querySnapshot = await getDocs(state.firebaseGetters.supplieritemsRef)
  querySnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      const data = doc.data()
      if (!data.costMoney) {
        data.costMoney = { amount: 0, currency: "CAD", unit: "units" }
      }
      supplierItems.push(data)
    }
  })
  commit(
    "setSupplierItems",
    supplierItems.sort((a, b) => a.name.localeCompare(b.name))
  )
  commit("setLoadText", "Loaded supplier items...")
}

export async function fetchInventoryItems({ commit, state }) {
  var inventoryItems = []
  const querySnapshot = await getDocs(state.firebaseGetters.itemsRef)
  querySnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      inventoryItems.push(doc.data())
    }
  })
  commit(
    "setInventoryItems",
    inventoryItems.sort((a, b) => a.name.localeCompare(b.name))
  )
  commit("setLoadText", "Loaded inventory items...")
}

export async function fetchUnits({ commit, state }) {
  var units = []
  var unitsSnapshot = await getDocs(state.firebaseGetters.unitsRef)
  unitsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      units.push(doc.data())
    }
  })
  units = units.sort((a, b) => a.name.localeCompare(b.name))
  commit("setUnits", units)
  commit("setLoadText", "Loaded units...")
}

export async function fetchStorages({ commit, state }) {
  var storages = []
  var storagesSnapshot = await getDocs(state.firebaseGetters.storagesRef)
  storagesSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      storages.push(doc.data())
    }
  })
  commit("setStorages", storages)
  commit("setLoadText", "Loaded storages...")
}

export async function fetchThirdPartyTypes({ commit, state }) {
  var thirdPartyTypes = []
  var thirdPartyTypesSnapshot = await getDocs(
    state.firebaseGetters.thirdpartytypesRef
  )
  if (thirdPartyTypesSnapshot) {
    thirdPartyTypesSnapshot.forEach(doc => {
      if (doc.data() && !doc.data().isDeleted) {
        thirdPartyTypes.push(doc.data())
      }
    })
  }
  commit("setThirdPartyTypes", thirdPartyTypes)
  commit("setLoadText", "Loaded third party types...")
}

export async function fetchOrderGuides({ commit, state }) {
  var orderGuides = []
  var orderGuidesSnapshot = await getDocs(state.firebaseGetters.orderguidesRef)
  orderGuidesSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      orderGuides.push(doc.data())
    }
  })
  commit("setOrderGuides", orderGuides)
  commit("setLoadText", "Loaded order guides...")
}

export async function fetchTaxes({ commit, state }) {
  var taxes = []
  var taxesSnapshot = await getDocs(state.firebaseGetters.taxesRef)
  taxesSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      taxes.push(doc.data())
    }
  })
  commit("setTaxes", taxes)
  commit("setLoadText", "Loaded taxes...")
}

export async function fetchCoupons({ commit, state }) {
  var coupons = []
  var couponsSnapshot = await getDocs(state.firebaseGetters.couponsRef)
  couponsSnapshot.forEach(doc => {
    if (doc.data()) {
      coupons.push(doc.data())
    }
  })
  commit("setCoupons", coupons)
  commit("setLoadText", "Loaded coupons...")
}

export async function fetchModifierLists({ commit, state }) {
  var modifiers = []
  var modifiersSnapshot = await getDocs(state.firebaseGetters.modifierlistsRef)
  modifiersSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      modifiers.push(doc.data())
    }
  })
  commit("setModifierLists", modifiers)
  commit("setLoadText", "Loaded modifier lists...")
}

export async function fetchOrders({ commit, state, dispatch, getters }) {
  const startOfToday = Timestamp.fromDate(moment().startOf("day").toDate())

  const todayOrders = query(
    state.firebaseRefs.ordersRef,
    where("orderDate", ">=", startOfToday)
  )

  const firebaseSnapshotUnsubscribeObject = onSnapshot(
    todayOrders,
    ordersSnapshot => {
      const orders = getters.getFilteredOrdersFromSnapshot(ordersSnapshot)

      const paidOrderIds = orders.filter(o => o.status !== "Pending").map(o => o.id)
      const newOrderIds = paidOrderIds.filter(id => !state.paidOrderIds.includes(id))

      commit("setPaidOrderIds", paidOrderIds)

      if (newOrderIds.length > 0) {
        newOrderIds.forEach(newOrderId => {
          commit("addOrderToPrint", newOrderId)
        })
        if (state.soundOn) {
          if (newOrderIds.length > 1) {
            commit("setNotification", {
              message: `${newOrderIds.length} new orders!`,
            })
          } else {
            commit("setNotification", { message: "New order!" })
          }
        }
      }
      commit("setOrders", orders)
    },
    err => {
      console.error("order snapshot error", err)
      setTimeout(() => {
        dispatch("fetchOrders")
      }, 10000)
    }
  )
  commit("setFirebaseSnapshotUnsubscribeObjects", firebaseSnapshotUnsubscribeObject)
  await dispatch("fetchRefunds")
  commit("setLoadText", "Loaded orders and refunds...")
}

export async function unsubscribeFromFirebaseSnapshots({ state }) {
  if (state.firebaseSnapshotUnsubscribeObjects?.length) {
    state.firebaseSnapshotUnsubscribeObjects.forEach(unsubscribe => {
      unsubscribe()
    })
  }
}

export async function updateOrder({ state, commit }, { payload, stateType }) {
  if (!payload.id) {
    payload.id = doc(state.firebaseRefs.ordersRef).id
  }
  await setDoc(doc(state.firebaseRefs.ordersRef, payload.id), payload, {
    merge: true,
  })
    .then(() => {
      const orders = [...state[stateType]]
      const orderIndex = orders.findIndex(order => order.id === payload.id)
      if (orderIndex !== -1) {
        orders[orderIndex] = { ...orders[orderIndex], ...payload }
      }
      switch (stateType) {
        case "orders":
          commit("setOrders", orders)
          break
        case "allOrders":
          commit("setAllOrders", orders)
          break
        case "allOpenOrders":
          commit("setAllOpenOrders", orders)
          break
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export async function fetchDiscountedOrders({ commit, state }) {
  const ordersRef = collection(
    db,
    "Organizations-v1",
    state.firebaseGetters.organization.id,
    "Orders"
  )

  const querySnapshot = await getDocs(
    query(ordersRef, where("appliedDiscount", "!=", null))
  )
  const discountedOrders = querySnapshot.docs
    .map(entry => entry.data())
    .filter(entry => !entry.isDeleted)

  commit("setDiscountedOrders", discountedOrders)
}

export async function fetchCustomerOrders({ commit, state }) {
  const kioskUserIds = state.customers
    .filter(
      c => c.email.startsWith("kiosk") && c.email.endsWith("@nextgenkitchens.com")
    )
    .map(c => c.uid)

  const ordersRef = collection(
    db,
    "Organizations-v1",
    state.firebaseGetters.organization.id,
    "Orders"
  )

  const querySnapshot = await getDocs(ordersRef)
  const customerOrders = querySnapshot.docs
    .map(entry => entry.data())
    .filter(entry => !kioskUserIds.includes(entry.userId) && !entry.isDeleted)

  commit("setCustomerOrders", customerOrders)
}

export async function fetchAllOpenOrders({ commit, state, getters }) {
  const ordersRef = collection(
    db,
    "Organizations-v1",
    state.firebaseGetters.organization.id,
    "Orders"
  )

  const ordersSnapshot = await getDocs(
    query(
      ordersRef,
      where("status", "in", [
        "Payment Accepted",
        "Accepted",
        "In Progress",
        "Ready For Pickup",
      ])
    )
  )
  const allOpenOrders = getters.getFilteredOrdersFromSnapshot(ordersSnapshot)

  commit("setAllOpenOrders", allOpenOrders)
}

export async function fetchAllOrders({ commit, state, getters }, dateRange) {
  // Return if all orders have already been loaded today
  const startOfToday = moment().startOf("day").toDate()
  if (
    state.allOrdersLastFetched &&
    state.allOrdersLastFetched > startOfToday &&
    !state.allOrdersDateRanges
  ) {
    return true
  }

  if (!dateRange) {
    // Load everything
    commit("setAllOrdersDateRanges", null)

    const ordersSnapshot = await getDocs(state.firebaseGetters.ordersRef)
    const allOrders = getters.getFilteredOrdersFromSnapshot(ordersSnapshot)
    commit("setAllOrders", allOrders)
  } else {
    // Load only the given date range if it has not been loaded
    let { startDate, endDate } = dateRange
    // load 2 extra days to include the last day and also account for timezone differences
    endDate = moment(endDate, "YYYY-MM-DD HH:mm:ss").add(2, "day").toDate()
    startDate = moment(startDate, "YYYY-MM-DD HH:mm:ss").toDate()

    // Check to see if this date range is already loaded or not
    if (!isDateRangeCovered({ startDate, endDate }, state.allOrdersDateRanges)) {
      commit("setAllOrdersDateRanges", { startDate, endDate })

      // Perform the Firebase query with the new date range
      const ordersRef = collection(
        db,
        "Organizations-v1",
        state.firebaseGetters.organization.id,
        "Orders"
      )

      const ordersSnapshot = await getDocs(
        query(
          ordersRef,
          where("orderDate", ">=", startDate),
          where("orderDate", "<=", endDate)
        )
      )
      const newOrders = getters.getFilteredOrdersFromSnapshot(ordersSnapshot)

      const mergedOrders = state.allOrders.concat(newOrders)
      const uniqueOrders = Array.from(new Set(mergedOrders.map(order => order.id)))
        .map(id => mergedOrders.find(order => order.id === id))
        .sort(function (a, b) {
          return new Date(a.orderDate) - new Date(b.orderDate)
        })

      commit("setAllOrders", uniqueOrders)
    } else if (
      state.allOrdersLastFetched &&
      moment(state.allOrdersLastFetched).isBefore(startOfToday)
    ) {
      // Data is already up-to-date for the given date range,
      // but it has been a day since the last fetch, so fetch again
      const ordersSnapshot = await getDocs(state.firebaseGetters.ordersRef)
      const allOrders = getters.getFilteredOrdersFromSnapshot(ordersSnapshot)

      commit("setAllOrders", allOrders)
    }
  }
}

export async function fetchRefunds({ commit, state }) {
  const querySnapshot = await getDocs(state.firebaseGetters.refundsRef)
  const refunds = querySnapshot.docs
    .map(entry => entry.data())
    .filter(entry => !entry.isDeleted)
    .sort(function (a, b) {
      return new Date(a.refundDate) - new Date(b.refundDate)
    })
  commit("setRefunds", refunds)
}

export async function fetchDevices({ commit, state }) {
  var devices = []
  var devicesSnapshot = await getDocs(state.firebaseGetters.devicesRef)
  devicesSnapshot.forEach(doc => {
    devices.push(doc.data())
  })

  // get docs from Users collection, filter and map to device format, concat with Devices collections, remove duplicates
  // getting devices from both Users and Devices for now
  const usersDevices = state.customers
    .filter(
      c =>
        (c.email.startsWith("kiosk") || c.email.startsWith("pos")) &&
        c.email.endsWith("@nextgenkitchens.com")
    )
    .map(d => ({
      type: d.email.startsWith("kiosk") ? "kiosk" : "pos",
      name: d.displayName,
      uid: d.uid,
    }))
    .concat(devices)
    .filter(
      (device1, i, self) =>
        self.findIndex(device2 => device2.uid === device1.uid) === i
    )

  commit("setDevices", usersDevices)
}

export async function fetchCustomers({ commit, state }) {
  var customers = []
  var customersSnapshot = await getDocs(state.firebaseGetters.usersRef)
  customersSnapshot.forEach(doc => {
    if (doc.data().role == "customer") {
      customers.push(doc.data())
    }
  })
  commit("setCustomers", customers)
  commit("setLoadText", "Loaded customers...")
}

export async function fetchCustomerCoupons({ commit, state }) {
  var customers = state.customers

  var availableCouponsSnapshots = []
  var redeemedCouponsSnapshots = []

  customers.forEach(customer => {
    const userId = customer.uid
    const availableCouponsSnapshot = getDocs(
      collection(
        db,
        "Organizations-v1",
        state.firebaseGetters.organization.id,
        "Users",
        userId,
        "AvailableCoupons"
      )
    )
    availableCouponsSnapshots.push(availableCouponsSnapshot)
    const redeemedCouponsSnapshot = getDocs(
      collection(
        db,
        "Organizations-v1",
        state.firebaseGetters.organization.id,
        "Users",
        userId,
        "RedeemedCoupons"
      )
    )
    redeemedCouponsSnapshots.push(redeemedCouponsSnapshot)
  })

  const availableCoupons = await Promise.all(availableCouponsSnapshots)
  const redeemedCoupons = await Promise.all(redeemedCouponsSnapshots)

  customers = customers.map((customer, i) => {
    customer.availableCoupons = []
    customer.redeemedCoupons = []
    availableCoupons[i].forEach(coupon => {
      const couponData = coupon.data()
      if (
        couponData &&
        Object.keys(couponData).length &&
        customer.availableCoupons.findIndex(
          coupon => couponData.id === coupon.id
        ) === -1
      ) {
        customer.availableCoupons.push(couponData)
      }
    })
    redeemedCoupons[i].forEach(coupon => {
      const couponData = coupon.data()
      if (
        Object.keys(couponData).length &&
        customer.redeemedCoupons.findIndex(
          coupon => couponData.couponId === coupon.couponId
        ) === -1
      )
        customer.redeemedCoupons.push(couponData)
    })
    return customer
  })

  commit("setCustomers", customers)
}

export async function fetchModifierItems({ commit, dispatch, state }) {
  var modifierItems = []
  var modifierItemsSnapshot = await getDocs(state.firebaseGetters.modifieritemsRef)
  modifierItemsSnapshot.forEach(doc => {
    if (!doc.data().isDeleted) {
      modifierItems.push(doc.data())
    }
  })
  modifierItems = modifierItems.sort((a, b) => {
    return a.ordinal > b.ordinal ? 1 : -1
  })

  for (let i = 0; i < modifierItems.length; i++) {
    if (
      modifierItems[i].isSnoozed &&
      modifierItems[i].snoozeUntil > 0 &&
      modifierItems[i].snoozeUntil <= Date.now()
    ) {
      modifierItems[i].isSnoozed = false
      modifierItems[i].snoozeUntil = -1
      dispatch("updateModifierItem", modifierItems[i])
    }
  }

  commit("setModifierItems", modifierItems)
  commit("setLoadText", "Loaded modifier items...")
}

export async function fetchChannels({ commit, state }) {
  var channels = []
  var channelsSnapshot = await getDocs(state.firebaseGetters.channelsRef)
  channelsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      channels.push(doc.data())
    }
  })
  commit("setChannels", channels)
  commit("setLoadText", "Loaded channels...")
}

export async function fetchOrgDoc({ commit, state }) {
  commit("setCategories", state.firebaseRefs.organization?.categories)
  commit("setSubcategories", state.firebaseRefs.organization?.subcategories)
  commit("setSuppliers", state.firebaseRefs.organization?.suppliers)
  commit("setLoadText", "Loaded org...")
}

export async function fetchSettings({ commit, state }) {
  var settingsOrg = await getDoc(
    doc(state.firebaseGetters.settingsRef, "Organization")
  )
  var settingsPrivate = await getDoc(
    doc(state.firebaseGetters.settingsRef, "Private")
  )
  var settingsPublic = await getDoc(doc(state.firebaseGetters.settingsRef, "Public"))
  commit("setSettings", {
    ...settingsOrg.data(),
    ...settingsPrivate.data(),
    ...settingsPublic.data(),
  })
  commit("setSettingsByVisibility", {
    Organization: settingsOrg.data(),
    Private: settingsPrivate.data(),
    Public: settingsPublic.data(),
  })
  commit("setLoadText", "Loaded settings...")
}

export async function updatePublicSettings({ dispatch, state }, payload) {
  const publicDocRef = doc(state.firebaseGetters.settingsRef, "Public")
  await setDoc(publicDocRef, payload, { merge: true })
  dispatch("fetchSettings")
}

export async function updateOrganizationSettings({ dispatch, state }, payload) {
  const orgDocRef = doc(state.firebaseGetters.settingsRef, "Organization")
  await setDoc(orgDocRef, payload, { merge: true })
  dispatch("fetchSettings")
}

export async function updateSettings({ dispatch, state }, payload) {
  const orgDocRef = doc(state.firebaseGetters.settingsRef, "Organization")
  const publicDocRef = doc(state.firebaseGetters.settingsRef, "Public")

  const orgPayload = {}
  const publicPayload = {}

  for (const item of payload) {
    const property = item.path.join(".") // joins elements by a period for the updateDoc call

    if (item.value == null) {
      continue
    }

    if (item.documents.includes("Organization")) {
      // check that documents field includes "Organization"
      orgPayload[property] = item.value
    }
    if (item.documents.includes("Public")) {
      // check that documents field includes "Public"
      publicPayload[property] = item.value
    }
  }

  await setDoc(orgDocRef, orgPayload, { merge: true })
  await setDoc(publicDocRef, publicPayload, { merge: true })

  dispatch("fetchSettings") // updates settings with new values
}

export async function fetchUsers({ commit, state }) {
  let docRef = doc(organizations, state.firebaseRefs.organization.id)

  onSnapshot(docRef, orgSnapshot => {
    commit("setUsers", orgSnapshot.data().access)
    commit("setLoadText", "Loaded users...")
  })
}

export async function updateModifierItem({ dispatch, state }, payload) {
  if (!payload.id) {
    payload.id = doc(state.firebaseRefs.modifieritemsRef).id
  }
  if (payload.isSnoozed === false) {
    payload.isSnoozed = false
    payload.snoozeUntil = -1
  }
  await setDoc(doc(state.firebaseRefs.modifieritemsRef, payload.id), payload, {
    merge: true,
  })
    .then(() => {
      dispatch("fetchModifierItems")
    })
    .catch(err => {
      console.error(err)
    })
}

export async function updateMenuItems({ dispatch, state }, payload) {
  if (!payload.id) {
    payload.id = doc(state.firebaseRefs.menuitemsRef).id
  }
  await setDoc(doc(state.firebaseRefs.menuitemsRef, payload.id), payload, {
    merge: true,
  }).then(() => {
    dispatch("fetchMenuItems")
  })
}

export async function saveOrdersAsCompleted({ commit, state }, payload) {
  const batch = writeBatch(db)
  let orders = [...state.orders]
  payload.forEach(order => {
    const orderIndex = orders.findIndex(o => o.id === order.orderId)
    if (orderIndex !== -1) {
      let shouldUpdate = false
      if (
        order.success &&
        !["Completed", "Refunded", "Cancelled"].includes(orders[orderIndex].status)
      ) {
        orders[orderIndex].status = "Completed"
        shouldUpdate = true
      }
      order.posOrders.forEach(posOrder => {
        // if (posOrder.success) {
        orders[orderIndex].restaurants.forEach((restaurant, j) => {
          if (restaurant.posOrderId === posOrder.posOrderId) {
            orders[orderIndex].restaurants[j].status = "Completed"
            shouldUpdate = true
          }
        })
        // }
      })
      if (shouldUpdate) {
        batch.update(
          doc(state.firebaseRefs.ordersRef, order.orderId),
          orders[orderIndex]
        )
      }
    }
  })
  await batch.commit().then(() => {
    commit("setOrders", orders)
    state.orders = [...new Set(orders)]
  })
}

export async function deleteModifierItem({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.modifieritemsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchModifierItems"))
}

export async function updateModifierList({ dispatch, state }, payload) {
  const { modList, modItems } = payload
  if (!modList.id) {
    modList.id = doc(state.firebaseRefs.modifierlistsRef).id
  }

  await setDoc(doc(state.firebaseRefs.modifierlistsRef, modList.id), modList, {
    merge: true,
  }).then(dispatch("fetchModifierLists"))

  modItems.forEach(modItem => {
    if (modItem.modifierListId === undefined) {
      modItem.modifierListId = modList.id
    }
    dispatch("updateModifierItem", modItem)
  })
}

export async function deleteModifierList({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.modifierlistsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchModifierLists"))
}

export async function updateTaxes({ commit, state }, payload) {
  var taxID
  if (payload.id) {
    taxID = payload.id
  } else {
    taxID = doc(state.firebaseRefs.taxesRef).id
    payload.id = taxID
  }
  await setDoc(doc(state.firebaseRefs.taxesRef, payload.id), payload).then(
    commit("addTax", payload)
  )
}

export async function updateTaxOrder({ dispatch, state }, payload) {
  const batch = writeBatch(db)
  for (let i = 0; i < payload.length; i++) {
    const taxRef = doc(state.firebaseRefs.taxesRef, payload[i].id)
    batch.update(taxRef, { order: payload[i].order })
  }
  await batch.commit().then(dispatch("fetchTaxes", payload))
}

export async function deleteTax({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.taxesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchTaxes"))
}

export async function deletePromoImage({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.promoimagesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchPromoImages"))
}

export async function updateCoupons({ dispatch, state }, payload) {
  var couponID
  if (payload.id) {
    couponID = payload.id
  } else {
    couponID = doc(state.firebaseRefs.couponsRef).id
    payload.id = couponID
  }
  await setDoc(doc(state.firebaseRefs.couponsRef, payload.id), payload).then(
    dispatch("fetchCoupons")
  )
}

export async function deleteCoupon({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.couponsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchCoupons"))
}

export async function updateCategories({ commit, state }, payload) {
  const currOrg = doc(organizations, state.firebaseRefs.organization.id)
  await updateDoc(currOrg, { categories: arrayUnion(payload) })
  commit("addCategory", payload)
}

export async function updateSubcategories({ commit, state }, payload) {
  const currOrg = doc(organizations, state.firebaseRefs.organization.id)
  await updateDoc(currOrg, { subcategories: arrayUnion(payload) })
  commit("addSubcategory", payload)
}

export async function updateStorages({ commit, state }, payload) {
  var storageID
  if (payload.id) {
    storageID = payload.id
  } else {
    storageID = doc(state.firebaseRefs.storagesRef).id
    payload.id = storageID
  }
  await setDoc(doc(state.firebaseRefs.storagesRef, payload.id), payload).then(
    commit("addStorage", payload)
  )
}

export async function deleteStorage({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.storagesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchStorages"))
}

export async function updateThirdPartyTypes({ commit, state }, payload) {
  var thirdPartyTypeID
  if (payload.id) {
    thirdPartyTypeID = payload.id
  } else {
    thirdPartyTypeID = doc(state.firebaseRefs.thirdpartytypesRef).id
    payload.id = thirdPartyTypeID
  }
  await setDoc(doc(state.firebaseRefs.thirdpartytypesRef, payload.id), payload).then(
    commit("addThirdPartyType", payload)
  )
}

export async function deleteThirdPartyType({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.thirdpartytypesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchThirdPartyTypes"))
}

export async function updateOrderGuides({ commit, state }, payload) {
  var orderGuideID
  if (payload.id) {
    orderGuideID = payload.id
  } else {
    orderGuideID = doc(state.firebaseRefs.orderguidesRef).id
    payload.id = orderGuideID
  }
  await setDoc(doc(state.firebaseRefs.orderguidesRef, payload.id), payload).then(
    commit("addOrderGuide", payload)
  )
}

export async function deleteOrderGuide({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.orderguidesRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchOrderGuides"))
}

export async function updateInventoryItems({ dispatch, state }, payload) {
  if (!payload.itemID) {
    payload.itemID = doc(state.firebaseRefs.itemsRef).id
  }
  payload.itemID = payload.itemID.toString()
  await setDoc(doc(state.firebaseRefs.itemsRef, payload.itemID), payload).then(
    dispatch("fetchInventoryItems")
  )
}

export async function deleteInventoryItem({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.itemsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchInventoryItems"))
}

export async function updateSupplierItems({ dispatch, state }, payload) {
  var supplierItemID
  if (payload.id) {
    supplierItemID = payload.id
  } else {
    supplierItemID = doc(state.firebaseRefs.supplieritemsRef).id
    payload.id = supplierItemID
  }
  await setDoc(doc(state.firebaseRefs.supplieritemsRef, payload.id), payload, {
    merge: true,
  }).then(dispatch("fetchSupplierItems"))
}

export async function deleteSupplierItem({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.supplieritemsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchSupplierItems"))
}

export async function updateSuppliers({ commit, state }, payload) {
  const currOrg = doc(organizations, state.firebaseRefs.organization.id)
  await updateDoc(currOrg, { suppliers: arrayUnion(payload) })
  commit("addSupplier", payload)
}

export async function updateUnits({ commit, state }, payload) {
  var unitID
  if (payload.id) {
    unitID = payload.id
  } else {
    unitID = doc(state.firebaseRefs.unitsRef).id
    payload.id = unitID
  }
  await setDoc(doc(state.firebaseRefs.unitsRef, payload.id), payload, {
    merge: true,
  }).then(commit("addUnit", payload))
}

export async function deleteUnit({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.unitsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchUnits"))
}

export async function updateUsers({ dispatch, state }, payload) {
  const currOrg = doc(organizations, state.firebaseRefs.organization.id)
  await updateDoc(currOrg, { access: payload })
  dispatch("fetchUsers")
}

export async function updateCustomers({ dispatch, state }, payload) {
  payload.user.createdAt = new Date()

  if (!payload.user.uid) {
    payload.user.uid = doc(state.firebaseRefs.usersRef).id
  }
  await setDoc(doc(state.firebaseRefs.usersRef, payload.user.uid), payload.user, {
    merge: true,
  }).then(() => {
    dispatch("fetchCustomers")
  })
}

export async function updateDevices({ state, dispatch }, payload) {
  var deviceID
  payload.createdAt = new Date()

  if (payload.uid) {
    deviceID = payload.uid
  } else {
    deviceID = doc(state.firebaseRefs.devicesRef).id
    payload.uid = deviceID
  }
  await setDoc(doc(state.firebaseRefs.devicesRef, payload.uid), payload, {
    merge: true,
  }).then(dispatch("fetchDevices"))
}

function deleteDeviceInDB(data) {
  const orgId = data.orgId
  const userId = data.userId

  try {
    const devicesRef = doc(
      collection(db, "Organizations-v1", orgId, "Devices"),
      userId
    )

    deleteDoc(devicesRef).catch(error => {
      console.error("Error deleting device:", error)
    })
  } catch (error) {
    console.error("Error in deleteDeviceInDB:", error)
  }
}

export async function deleteDevice({ dispatch, state }, payload) {
  const deleteSquareUser = httpsCallable(functions, "deleteSquareUser")
  const deleteStripeUser = httpsCallable(functions, "deleteStripeUser")
  const deleteUserInDB = httpsCallable(functions, "deleteUserInDB")
  const deleteFBUserInDB = httpsCallable(functions, "deleteFBUserInDB")

  let promiseArray = []

  if (state.settings.paymentProvider === "stripe") {
    promiseArray.push(
      deleteStripeUser({
        orgId: state.firebaseRefs.organization.id,
        userId: payload.uid,
      })
    )
  } else if (state.settings.paymentProvider === "square") {
    promiseArray.push(
      deleteSquareUser({
        uid: payload.uid,
        orgId: state.firebaseRefs.organization.id,
      })
    )
  }

  promiseArray.push(
    deleteDeviceInDB({
      orgId: state.firebaseRefs.organization.id,
      userId: payload.uid,
    }),
    deleteUserInDB({
      orgId: state.firebaseRefs.organization.id,
      userId: payload.uid,
    }),
    deleteFBUserInDB({ userId: payload.uid })
  )

  await Promise.all(promiseArray).then(() => {
    dispatch("fetchDevices")
    dispatch("fetchCustomers")
  })
}

export async function updateRestaurants({ dispatch, state }, payload) {
  var restaurantID
  if (payload.id) {
    restaurantID = payload.id
  } else {
    restaurantID = doc(state.firebaseRefs.restaurantsRef).id
    payload.id = restaurantID
  }
  await updateDoc(doc(state.firebaseRefs.restaurantsRef, payload.id), payload)
  dispatch("fetchRestaurants")
}

export async function addRestaurant({ dispatch, state }, payload) {
  var restaurantID = doc(state.firebaseRefs.restaurantsRef).id
  payload.id = restaurantID
  await setDoc(doc(state.firebaseRefs.restaurantsRef, payload.id), payload)
  dispatch("fetchRestaurants")
  return new Promise(resolve => {
    resolve(restaurantID)
  })
}

export async function updateDeliveryStatus({ dispatch, state }, payload) {
  var currRestauant = doc(state.firebaseRefs.restaurantsRef, payload.id)
  await updateDoc(currRestauant, { "enabledFeatures.delivery": payload.val }).then(
    dispatch("fetchRestaurants")
  )
}

export async function updateRestaurantStatus({ dispatch, state }, payload) {
  var currRestauant = doc(state.firebaseRefs.restaurantsRef, payload.id)
  await updateDoc(currRestauant, {
    isSnoozed: payload.isSnoozed,
    snoozeUntil: payload.snoozeUntil,
  }).then(dispatch("fetchRestaurants"))
}

export async function deleteRestaurant({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.restaurantsRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchRestaurants"))
}

//Currently only able to update a channel name
export async function updateChannel({ dispatch, state }, payload) {
  var channelID
  if (payload.id) {
    channelID = payload.id
  } else {
    channelID = doc(state.firebaseRefs.channelsRef).id
    payload.id = channelID
  }
  var currChannel = doc(state.firebaseRefs.channelsRef, channelID)
  await setDoc(currChannel, payload).then(dispatch("fetchChannels"))
}

export async function fetchPurchaseOrders({ commit, state }) {
  var orders = []
  var ordersSnapshot = await getDocs(state.firebaseGetters.purchaseordersRef)
  ordersSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      orders.push(doc.data())
    }
  })
  orders = orders.sort((a, b) => {
    return a.date > b.date ? 1 : -1
  })
  commit("setPurchaseOrders", orders)
  commit("setLoadText", "Loaded purchase orders...")
}

export async function fetchCounts({ commit, state }) {
  var counts = []
  var countsSnapshot = await getDocs(state.firebaseGetters.countsRef)
  countsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      counts.push(doc.data())
    }
  })
  counts = counts.sort((a, b) => {
    return a.date > b.date ? 1 : -1
  })
  commit("setCounts", counts)
  commit("setLoadText", "Loaded counts...")
}

export async function fetchWriteoffs({ commit, state }) {
  var writeoffs = []
  var writeoffsSnapshot = await getDocs(state.firebaseGetters.writeoffsRef)
  writeoffsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      writeoffs.push(doc.data())
    }
  })
  writeoffs = writeoffs.sort((a, b) => {
    return a.date > b.date ? 1 : -1
  })
  commit("setWriteoffs", writeoffs)
  commit("setLoadText", "Loaded writeoffs...")
}

export async function fetchReceivings({ commit, state }) {
  var receivings = []
  var receivingsSnapshot = await getDocs(state.firebaseGetters.receivingsRef)
  receivingsSnapshot.forEach(doc => {
    if (doc.data() && !doc.data().isDeleted) {
      receivings.push(doc.data())
    }
  })
  receivings = receivings.sort((a, b) => {
    return a.date > b.date ? 1 : -1
  })
  commit("setReceivings", receivings)
  commit("setLoadText", "Loaded receivings...")
}

export async function updatePurchaseOrders({ commit, state }, payload) {
  var orderId
  if (payload.id) {
    orderId = payload.id
  } else {
    orderId = doc(state.firebaseRefs.purchaseordersRef).id
    payload.id = orderId
  }
  await setDoc(doc(state.firebaseRefs.purchaseordersRef, payload.id), payload).then(
    commit("addPurchaseOrder", payload)
  )
}

export async function deletePurchaseOrder({ dispatch, state }, payload) {
  await updateDoc(doc(state.firebaseRefs.purchaseordersRef, payload.id), {
    isDeleted: true,
  }).then(dispatch("fetchPurchaseOrders"))
}
