import { functions } from "@/firebaseConfig"
import { httpsCallable } from "firebase/functions"
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["units", "supplierItems"]),
    ...mapGetters(["getUnitName"]),
  },
  methods: {
    /***************************************/
    /*   SUPPLIER ITEMS
    /***************************************/

    getUnitCostFromCostMoney(costMoney, supplierUnit) {
      return (
        (costMoney?.amount || 0) *
        parseFloat(this.units.find(unit => unit.id == supplierUnit)?.valInBase || 1)
      )
    },
    getCostMoneyFromUnitCost(unitCost, supplierUnit) {
      return {
        amount:
          unitCost /
          parseFloat(this.units.find(unit => unit.id == supplierUnit)?.valInBase),
        currency: "CAD",
        unit: this.units.find(unit => unit.id == supplierUnit)?.base,
      }
    },
    getCostPerUnit(item) {
      if (!item?.costMoney || !item.packageMeasurement.preferredDisplayUnitId)
        return 0
      return (
        (item.costMoney.amount || 0) *
        parseFloat(
          this.units.find(
            unit => unit.id == item.packageMeasurement.preferredDisplayUnitId
          )?.valInBase
        )
      )
    },
    getCostPerPackage(item) {
      if (
        !item?.costMoney ||
        !item.packageMeasurement?.quantity ||
        !item.packageMeasurement.linkedItemID
      ) {
        const supplierItem = this.supplierItems.find(
          supplierItem => supplierItem.id === item.supplierItemId
        )
        item = { ...item, ...supplierItem }
      }
      if (!item.packageMeasurement) {
        item.packageMeasurement = this.createEmptyMeasurementObject()
      }
      return (
        this.getUnitCostFromCostMoney(
          item.costMoney,
          item.packageMeasurement.preferredDisplayUnitId
        ) * this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id)
      )
    },
    getCostPerPackageOrCase(item) {
      const caseMultiplier = item.quantityUnit === "pkgs" ? 1 : item.caseSize || 1
      return this.getCostPerPackage(item) * caseMultiplier
    },
    /**
     * Converts a quantity from a supplier item's unit to the base unit
     * @param {number} quantity The quantity to convert
     * @param {number} itemId The ID of the linked item
     * @returns {number} The converted quantity
     */
    getQuantityInBaseUnits(quantity, id) {
      // Find the supplier item with the given ID
      const supplierItem = this.supplierItems.find(item => item.id === id)

      if (supplierItem) {
        // Find the preferred display unit for the supplier item
        const displayUnit = this.units.find(
          unit => unit.id === supplierItem.packageMeasurement?.preferredDisplayUnitId
        )

        // Define the rounding factor
        const roundingFactor = 100

        if (displayUnit && displayUnit.base) {
          // If a display unit with a base value is found, convert the quantity to the base unit
          return (
            Math.round((quantity / displayUnit.valInBase) * roundingFactor) /
            roundingFactor
          )
        } else {
          // If no display unit with a base value is found, return the quantity rounded to two decimal places
          return Math.round(quantity * roundingFactor) / roundingFactor
        }
      }

      return 0
    },
    getPackageMeasurementWithUnit(item, multiplier = 1) {
      if (!item.packageMeasurement) return "N/A"
      return (
        this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
          multiplier +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) > 1
        )
      )
    },
    getCaseMeasurementWithUnit(item, multiplier = 1) {
      if (!item.packageMeasurement || !item.caseSize) return "N/A"
      const qty =
        this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) *
        item.caseSize
      return (
        qty * multiplier +
        " " +
        this.getUnitName(
          item.packageMeasurement.preferredDisplayUnitId,
          this.getQuantityInBaseUnits(item.packageMeasurement.quantity, item.id) > 1
        )
      )
    },

    /***************************************/
    /*   ITEMS
    /***************************************/

    /**
     * Turn the measurement object into a text string for display
     * @param {Object} measurement The measurement object to convert
     * @returns {string} The measurement object as a text string
     * @example "5 kg"
     */
    getMeasurementForDisplay(measurement) {
      if (
        !measurement?.preferredDisplayUnitId ||
        !measurement.quantity ||
        !measurement.unit
      )
        return "0"

      let unit = this.units.find(u => u.id === measurement.preferredDisplayUnitId)
      if (unit) {
        return (
          Math.round((measurement.quantity / unit.valInBase) * 100) / 100 +
          " " +
          this.getUnitName(measurement.preferredDisplayUnitId, true)
        )
      } else {
        return Math.round(measurement.quantity * 100) / 100 + " " + measurement.unit
      }
    },
    createEmptyMeasurementObject() {
      return {
        quantity: null,
        unit: "",
        preferredDisplayUnitId: "",
      }
    },

    /***************************************/
    /*   MONEY
    /***************************************/

    formatMoneyAmount(amount, forSaving = true) {
      if (isNaN(amount) || !amount) amount = 0

      if (forSaving) return parseInt(amount)
      else {
        return new Intl.NumberFormat("en-CA", {
          style: "currency",
          currency: "CAD",
        }).format(parseFloat(amount / 100))
      }
    },

    /***************************************/
    /*   ADDRESS
    /***************************************/

    convertAddressObjectToString(addressObj) {
      if (addressObj) {
        let address = ""
        if (addressObj.addressLine1) address += addressObj.addressLine1 + ", "
        if (addressObj.addressLine2) address += addressObj.addressLine2 + ", "
        if (addressObj.city) address += addressObj.city + ", "
        if (addressObj.provinceState) address += addressObj.provinceState + ", "
        if (!addressObj.country && !addressObj.postCode)
          address = address.slice(0, -2)
        if (addressObj.country) address += addressObj.country + " "
        if (addressObj.postCode) address += addressObj.postCode
        return address.trim()
      }
      return ""
    },

    /***************************************/
    /*   OPEN AI
    /***************************************/

    async getOpenAiAnswer(prompt, maxTokens = 50, temperature = 0) {
      try {
        const response = await fetch("https://api.openai.com/v1/completions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
            //   "OpenAI-Organization": process.env.VUE_APP_OPENAI_ORG_ID,
          },
          body: JSON.stringify({
            model: "text-davinci-003",
            prompt: prompt,
            temperature: temperature,
            max_tokens: maxTokens,
          }),
        })

        const data = await response.json()
        return data.choices[0].text.trim()
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },

    /***************************************/
    /*   BACKEND
    /***************************************/

    async createSquareUser(user, orgId) {
      const createSquareUser = httpsCallable(functions, "createSquareUser")
      const response = await createSquareUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        uid: user.uid,
        orgId,
      })
      return response.data
    },

    async createStripeUser(user, orgId) {
      const createStripeUser = httpsCallable(functions, "createStripeUser")
      const response = await createStripeUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        firebaseUserId: user.uid,
        role: user.role,
        platform: "mobile app",
        organization: orgId,
      })
      return response.data
    },

    async getAvailableStripeReaders() {
      const requestObj = {
        orgId: this.firebaseRefs.organization.id,
        stripeLocationId: this.settings.stripeLocationId,
      }

      return (await httpsCallable(functions, "stripeReaderList")(requestObj))?.data
        ?.data
    },

    async getAvailableSquareDevices(restaurantId) {
      const requestObj = {
        restaurantId,
        orgId: this.firebaseRefs.organization.id,
      }

      const response = await httpsCallable(
        functions,
        "squareDevicesList"
      )(requestObj)
      return response.data
    },

    async createSquareTerminalPingAction(terminal) {
      const requestObj = {
        deviceId: terminal.deviceId,
        restaurantId: terminal.restaurantId,
        orgId: this.firebaseRefs.organization.id,
      }

      const response = await httpsCallable(
        functions,
        "createSquareTerminalPingAction"
      )(requestObj)
      return response.data
    },

    async getSquareTerminalPingAction(terminalPing, terminal) {
      const requestObj = {
        actionId: terminalPing.id,
        orgId: this.firebaseRefs.organization.id,
        restaurantId: terminal.restaurantId,
      }

      const response = await httpsCallable(
        functions,
        "getSquareTerminalPingAction"
      )(requestObj)
      return response.data
    },

    async createSquareDeviceCode(orgId, restaurantId, name) {
      const createDeviceCode = httpsCallable(functions, "createSquareDeviceCode")
      const response = await createDeviceCode({
        orgId,
        restaurantId,
        name,
      })
      return response.data.code
    },

    async listSquareDevicesCodes(orgId, restaurantId) {
      const listSquareDevicesCodes = httpsCallable(
        functions,
        "squareDevicesListCustomStatus"
      )
      const response = await listSquareDevicesCodes({
        orgId,
        restaurantId,
      })
      return response.data
    },

    async registerStripePinpad(orgId, registrationCode, label) {
      const registerStripePinpad = httpsCallable(functions, "registerStripePinpad")
      const response = await registerStripePinpad({
        orgId,
        registrationCode,
        label,
      })
      return response
    },

    async getUidByEmail(email) {
      const response = await httpsCallable(functions, "getUidByEmail")(email)
      return response.data
    },

    async sendEmailPasswordReset(
      email,
      orgId,
      orgName,
      orgLogo,
      webURL,
      numberOfMinutes = 10080
    ) {
      const sendEmailPasswordReset = httpsCallable(
        functions,
        "sendEmailPasswordReset"
      )
      const response = await sendEmailPasswordReset({
        userEmail: email.toLowerCase(),
        orgId,
        orgName,
        orgLogo,
        webURL,
        numberOfMinutes,
        isRM: true,
      })
      return response
    },

    async setPassword(password, token) {
      const setPassword = httpsCallable(functions, "handleEmailPasswordReset")
      const response = await setPassword({
        password,
        token,
        isRM: true,
      })
      return response
    },
  },
}
